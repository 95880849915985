.login-container {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}
.login-header {
    height: 25%;
    width: 100%;
    background-color: #011627;
    background-color: #9A031E;
    color: #9A031E;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-header-title {
    font-size: calc(1.5vw + 1.5vh);
    color: #FDFFFC;
    user-select: none;
}
.login-body {
    height: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #9A031E;
    background-color: #FDFFFC;
    background-color: #011627;
    background-color: #FDFFFC;
    padding: 5vh;
}
.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 80%;
}
.login-form-label {
    font-size: calc(1vw + 1vh);
    color: #011627;
    margin: 2vh;
    max-width: 80%;
}
.login-form-field {
    font-size: calc(1vw + 1vh);
    max-width: 80%;
}
.login-form-button {
    background-color: #9A031E;
    background-color: #011627;
    background-color: #9A031E;
    border: none;
    color: white;
    padding: 1vh 1vw;
    margin: 4vh;
    cursor: pointer;
    border-radius: 0;
    scale: 1.5;
    max-width: 50%;
}
.login-form-button:active {
    scale: 1.4;
}