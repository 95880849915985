* {
    margin: 0;
}
.navbar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 12vw;
    height: 100vh;
    margin-left: 0;
    background-color: #011627;
    background-color: #9A031E;
}
.navbar-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20vh;
    width: 100%;
    background-color: #011627;
    background-color: #9A031E;
}
.navbar-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
.navbar-logo:hover {
    transform: scale(1.25);
    transition: all ease-in-out 0.3s;
}
.navbar-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20vh;
    height: 60vh;
    width: 100%;
}
.navbar-menu-item {
    font-family:'Times New Roman', Times, serif;
    font-size: calc(1.0vw + 1.0vh);
    text-decoration: none;
    color: #FDFFFC;
    padding: 15px 0;
}
.navbar-menu-item:not(.active):hover {
    transform: scale(1.25);
    transition: all ease-in-out 0.2s;
}
.navbar-menu-item.active {
    text-align: center;
    background-color: #011627;
    width: 100%;
}
.navbar-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #011627;
    background-color: #9A031E;
    width: 100%;
    height: 15vh;
}

.navbar-footer-menu-item {
    font-family:'Times New Roman', Times, serif;
    font-size: calc(1.0vw + 1.0vh);
    text-decoration: none;
    color: #FDFFFC;
    padding: 15px 0;
}
.navbar-footer-menu-item:hover {
    transform: scale(1.25);
    transition: all ease-in-out 0.2s;
}
.navbar-divider {
    width: 75%;
    margin-top: 0vh;
}
.navbar-container-mobile {
    display: none
}
@media screen {
    
}